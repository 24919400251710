<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: 'App',
        mounted() {
            localStorage.clear();
            if (this.$cookies.isKey('aerdesk')) {
                localStorage.setItem('jwt', this.$cookies.get('aerdesk').jwt)
            }
        }
    }
</script>

<style lang="scss">
    // Import Main styles for this application
    @import 'assets/scss/style';
</style>
