import axiosBase from 'axios'

let baseURL = 'https://aerdesk-dot-ruckus-vsz-257719.uc.r.appspot.com'
let user_router = "users";
// creating axios instance
const axios = axiosBase.create({
    baseURL
})

axios.interceptors.request.use(config => {
    handleRequestInterceptor(config)

    return config
})

// list of api
const api = {

    search_user: params => axios({
        url: `${user_router}/search/by_params_or?params=${params}`,
        method: 'POST',
        data: params
    }),
    get_bcm: usr_email => axios({
        url: `${user_router}/search/broadcast_matrix?usr_email=${usr_email}`,
        method: 'POST'
    }),
    get_ap: (rap_mac, rctl_id) => axios({
        url: `${user_router}/search/ap?ap_mac=${rap_mac}&rctl_id=${rctl_id}`,
        method: "GET"
    }),
    get_clients: (rctl_id, usr_email, rwlan_wlanid) => axios({
        url: `${user_router}/search/clients?rctl_id=${rctl_id}&wlanname=${usr_email}&wlan_id=${rwlan_wlanid}`,
        method: "GET"
    }),
    login: (email, password) => axios({
        url: '/admin/login',
        method: "POST",
        data: {
            email: email,
            password: password
        }
    }),
    logout: (jwt) => axios({
        url: '/admin/logout',
        headers: {
            Authorization: 'Bearer ' + jwt
        }
    }),
    validate_session: (jwt) => axios({
        url: '/admin/validate',
        method: "GET",
        headers: {
            Authorization: 'Bearer ' + jwt
        }
    })
}

function handleRequestInterceptor(config) {
    try {
        const sid = localStorage.getItem('sid')
        if (config.params && sid) {
            config.params.sid = sid
        } else if (sid) {
            config.params = {
                sid
            }
        } else {
        }
    } catch (err) {
        console.log('err', err)
    }

}

export {
    baseURL,
    api
}