// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['556 134',`
<g transform="translate(1.000000,230.000000) scale(0.11,-0.1)"
fill="#000000" stroke="none">
<path d="M559 2013 c-33 -18 -67 -45 -88 -73 -23 -31 -45 -48 -72 -56 -80 -24
-154 -89 -190 -168 -13 -30 -19 -65 -19 -126 0 -76 3 -91 29 -136 69 -120 210
-187 329 -155 36 10 43 16 40 34 -3 21 -8 22 -88 22 -68 0 -93 5 -126 22 -146
78 -175 272 -59 389 33 33 59 48 104 60 54 15 63 22 90 65 37 59 90 89 161 89
81 0 130 -32 176 -115 6 -11 34 -26 68 -36 71 -21 118 -57 152 -115 17 -29 32
-44 43 -42 42 8 8 95 -61 153 -38 32 -103 64 -133 65 -12 1 -31 19 -50 48 -63
98 -195 130 -306 75z"/>
<path d="M728 1779 c-20 -11 -68 -89 -91 -147 -27 -69 -38 -170 -26 -243 6
-35 12 -70 12 -76 1 -21 35 -46 53 -39 12 5 15 13 10 34 -39 172 -26 274 50
389 19 28 34 56 34 61 0 18 -25 31 -42 21z"/>
<path d="M782 1693 c-76 -108 -98 -233 -61 -350 11 -34 28 -46 48 -33 10 6 10
23 0 85 -12 68 -11 84 4 143 10 37 31 87 48 110 24 35 27 47 18 58 -17 21 -36
17 -57 -13z"/>
<path d="M857 1628 c-50 -66 -68 -162 -46 -241 11 -42 31 -56 53 -38 11 9 12
20 5 44 -19 67 -7 142 33 200 40 57 -3 91 -45 35z"/>
<path d="M1016 1618 c-22 -13 -53 -40 -70 -61 -26 -33 -31 -48 -34 -105 -3
-82 20 -130 86 -177 38 -26 52 -30 105 -30 36 1 74 7 90 16 l29 16 -26 16
c-21 14 -26 25 -26 58 0 36 -2 39 -19 29 -74 -39 -153 40 -106 107 28 40 101
37 124 -4 5 -11 12 -49 14 -85 3 -37 10 -75 16 -86 12 -22 62 -55 99 -67 23
-7 23 -6 17 77 l-6 83 19 -40 c50 -103 157 -147 277 -114 46 13 115 77 115
108 0 19 -5 21 -63 21 -51 0 -68 -4 -82 -20 -33 -36 -105 -18 -118 30 -5 19
-1 20 134 20 l139 0 0 -75 c0 -81 -3 -77 65 -77 81 0 75 -9 75 102 0 121 12
140 85 140 47 0 47 0 47 55 0 64 0 65 -36 65 -18 0 -48 -10 -68 -23 -34 -24
-34 -24 -34 -3 1 19 -5 21 -64 21 l-65 0 1 -75 0 -74 -13 41 c-25 75 -112 133
-198 133 -84 0 -185 -68 -200 -135 -12 -56 -18 -61 -25 -23 -9 47 -44 98 -88
129 -51 34 -143 38 -196 7z"/>
<path d="M2700 1618 c-136 -73 -147 -254 -21 -343 37 -26 52 -30 104 -29 34 0
76 6 92 13 l30 12 -27 21 c-21 14 -28 28 -28 52 l0 33 -48 -5 c-52 -5 -75 11
-87 59 -9 38 31 82 74 82 51 0 72 -29 78 -109 6 -87 17 -110 69 -140 67 -39
67 -39 59 94 -8 142 -32 203 -96 248 -58 40 -137 45 -199 12z"/>
<path d="M3441 1614 c-76 -38 -106 -87 -106 -174 0 -89 32 -141 112 -177 100
-46 226 -18 274 61 30 50 26 56 -42 56 -46 0 -67 -5 -86 -20 -14 -11 -37 -20
-50 -20 -24 0 -73 40 -73 60 0 6 49 10 140 10 l140 0 0 38 c-1 147 -167 236
-309 166z"/>
<path d="M2010 1626 c0 -4 108 -306 125 -348 6 -14 19 -18 65 -18 l58 0 28
110 c27 104 42 134 45 90 2 -22 3 -28 12 -50 3 -8 15 -46 27 -83 l22 -68 56 3
57 3 62 170 c34 94 62 176 62 183 1 8 -17 12 -58 12 l-58 0 -29 -100 c-16 -55
-31 -100 -34 -100 -3 0 -18 45 -34 100 l-29 100 -63 0 -63 0 -12 -42 c-6 -24
-19 -72 -28 -108 l-18 -64 -35 104 -35 105 -61 3 c-34 2 -62 1 -62 -2z"/>
<path d="M2953 1608 c3 -7 23 -56 45 -108 22 -52 54 -130 72 -172 l32 -78 61
0 62 0 70 176 c39 97 72 181 74 186 2 5 -23 8 -59 6 l-62 -3 -41 -114 -40
-114 -42 114 -43 114 -67 3 c-52 2 -66 0 -62 -10z"/>
</g>
`]
