import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import {iconsSet as icons} from './assets/icons/icons.js'
import store from './store'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import wysiwyg from "vue-wysiwyg";

import "vue-wysiwyg/dist/vueWysiwyg.css";
import VueWorker from 'vue-worker'
Vue.use(require('vue-cookies'))
Vue.$cookies.config('7d', '', '', true)

Vue.use(VueWorker)
Vue.use(VueWorker, '$webthread')

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(VueToast)


Vue.prototype.$baseURL = 'https://aerdesk-dot-ruckus-vsz-257719.uc.r.appspot.com'
Vue.prototype.$log = console.log.bind(console)
new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    }
})

Vue.use(wysiwyg, {maxWidth: "400px"});
Vue.mixin({
    data() {
        return {

            whoamI: null
        }
    },
    methods: {}
})
